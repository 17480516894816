.invisibleWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: #000000BD;
}
.newStoreFormWrapper{
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #fff;
}
.newStoreFormWrapper h1{
    color: var(--primaryColor);
}
.newStoreFormWrapper form{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.newStoreFormWrapper input{
    margin: 1rem 0;
    padding: 0.5rem;
    font-size: 1.7rem;
    border: none;
    background-color: #D9D9D9;
}
.newStoreFormWrapper input:focus{
    outline: none;
}
.btnWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 20px;
}
.btnWrapper button{
    width: 115px;
    border: none;
    background-color: #393938;
    padding: 15px 10px;
    color: #fff;
    font-size: 20px;
    font-family: 'inter';
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
}
.error-msg{
    height: 1.2rem;
    font-size: 1.2rem;
    color: red;
}
@media screen and (min-width: 992px){
    .newStoreFormWrapper{
        width: 700px;
    }
}

