.modalContainer{
    background-color: #fff;
    padding: 40px;
}
.answerContainer{
    display: flex;
    justify-content: space-between;
}
.answerContainer p{
    cursor: pointer;
    transition: 0.4s all ease;
    padding: 10px;
    border-radius: 4px;
}
.answerContainer p:hover{
    background-color: var(--primaryColor);
    color: #fff;
}