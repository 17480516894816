.superAdminContainer{
    width: 90%;
    margin: 0 auto;
}
.superAdminHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.superAdminHeader h1{
    font-size: 2.5rem;
    color: var(--primaryColor);
}
.logoutContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn-logout{
    padding: 5px;
    color: #fff;
    background-color: var(--primaryColor);
    border-radius: 4px;
    cursor: pointer;
}
.btn-add{
    background-color: #393938;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}
.btn-add img{
    display: block;
    width: 25px;
}
.storesContainer>div:nth-child(odd){
    background-color: #D9D9D94F;
}
@media screen and (min-width: 768px){
    .superAdminContainer{
        width: 80%;
    }
    .superAdminHeader h1{
        font-size: 3rem;
    }
}