.cardForm{
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
}
.cardElement{
    margin: 0 auto 50px;
}
.cardForm button{
    border: transparent;
    border-radius: 4px;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s all ease;
    padding: 5px;
}
.cardForm button:hover{
    background-color: var(--primaryColor);
    color: #fff;
}