/* single product */
.singleProductContainer{
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    margin: 20px 0;
    padding: 10px 0;
}
.productProfile{
    display: flex;
    align-items: center;
    margin-left: 7%;
}
.productProfile>div{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
}
.productImg{
    width: 40px;
    height: 40px;
    background-color: #D9D9D9;
    margin-right: 10px;
}
.productName{
    margin: 0;
    justify-self: left;
}
.btn-modificator img{
    cursor: pointer;
}
/* single product */
/* single order */
.singleOrderContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0;
    cursor: pointer;
}
.singleOrderContainer:hover{
    background-color: var(--primaryColor) !important;
    color: #fff;
    transition: 0.3s all ease;
}
/* single order */
/* single order-product */
.singleOrderProductContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 20px 0;
}
/* single order-product */