.singleStoreContainer{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    margin: 20px 0;
    padding: 10px 0;
}
.storeProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.storeImg{
    width: 40px;
    height: 40px;
    background-color: #D9D9D9;
}
.storeName{
    margin: 0;
}
.btn-disable{
    background-color: #393938;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
.btn-remove img{
    width: 30px;
    cursor: pointer;
}
@media screen and (min-width: 992px){
    .storeProfile{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: end;
    }
    .storeName{
        justify-self: flex-start;
        align-self: flex-start;
        margin-left: 10px;
    }
    .btn-disable{
        width: 300px;
        margin: 0 auto;
    }
}