.orderPreviewContainer{
    width: 90%;
    padding-bottom: 50px;
    background-color: #fff;
}
.orderPreviewHeaderContainer{
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}
@media screen and (min-width: 992px){
    .orderPreviewContainer{
        width: 700px;
    }
}