.filterTagContainer{
    display: flex;
    align-items: center;
}
.filterTag{
    width: 130px;
    font-size: 1.3rem;
    margin-right: 20px;
    padding: 10px 0;
    border-radius: 4px;
    background-color: #393938;
    color: #fff;
    cursor: pointer;
    transition: 0.3s all ease;
}