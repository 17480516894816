@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --primaryColor: #069E2D;
}
*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input{
  font-family: 'League Spartan', sans-serif;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C4C4C4;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C4C4C4;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #C4C4C4;
}
@media screen and (min-width: 992px){
  h1{
    font-size: 2.6rem;
  }
  h2{
    display: flex;
    font-size: 2.4rem;
  }
  h2 img{
    width: 2rem;
    align-self: baseline;
    margin-left: 5px;
  }
}