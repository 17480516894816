.customizeProductContainer{
    position: relative;
    width: 90%;
    height: 100vh;
    overflow-y: auto;
    margin: 0 auto;
    background-color: #fff;
}
.customizeProductContainer>div:nth-child(even){
    background-color: #D9D9D94F;
}
.customizeProductContainer img{
    cursor: pointer;
}
.imagesContainer{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    padding: 20px 0;
    overflow-x: auto;
}
.singleInput{
    position: relative;
}
.singleInput input{
    position: absolute;
    bottom: 0;
    left: 40%;
    width: 0;
    height: 0;
    opacity: 0;
}
.singleInput .thumbnail{
    max-width: 150px;
    max-height: 110px;
    cursor: pointer; 
 } 
.cancelContainer,
.nameContainer,
.sizeContainer,
.priceContainer,
.customerContainer,
.typeContainer{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    padding: 15px 0;
}
.columnTitle{
    width: fit-content;
    margin-left: 10px;
    font-size: 1rem;
}
.customizeInput{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
}
.cancelContainer{
    padding: 5px 0;
}
.btn-cancel{
    width: 50px;
    justify-self: center;
}
.sizeContainer>div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sizeContainer>div>p{
    width: 35px;
    margin: 0 15px;
}
.customizeProductContainer .btn-modificator{
    width: 30px;
    align-self: center;
    justify-self: center;
}
.columnValue{
    cursor: pointer;
}
.size{
    width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: 0;
    background-color: #393938;
    color: #fff;
    align-self: center;
    justify-self: center;
    border-radius: 4px;
}
.customerContainer .size{
    padding: 5px 40px;
}
.doneContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.doneContainer p{
    width: fit-content;
    height: fit-content;
    padding: 10px 30px;
    align-self: center;
    justify-self: center;
    border-radius: 4px;
    background-color: #393938;
    color: #fff;
    cursor: pointer;
}
@media screen and (min-width: 992px){
    .columnTitle{
        margin-left: 60px;
        font-size: 1.2rem;
    }
    .customizeInput,
    .columnValue{
        font-size: 1.2rem;
    }
}