.jumper{
    width: 100px;
    height: 100px;
    background-color: var(--primaryColor);
    border-radius: 50%;
    transform: scale(0.3);
    box-shadow: inset 0px 0px 8px 14px #00000015, -5px 10px 2px -10px #00000050;
    animation: jumping 3s cubic-bezier(.55,.1,.4,.96) infinite;
}

@keyframes jumping{
    50%{
        box-shadow: inset 0px 0px 4px 2px #00000015, -5px 10px 25px 15px #00000050;
        transform: scale(1);
    }
    100%{
        transform: scale(0.3);
        box-shadow: inset 0px 0px 8px 14px #00000015, -5px 10px 2px -10px #00000050;
    }
}