.adminContainer{
    width: 90%;
    margin: 0 auto;
}
.adminContainer h1{
    color: var(--primaryColor);
}
.adminHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.adminHeader>div{
    text-align: left;
}
.adminHeader img{
    cursor: pointer;
}