.homeWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.homeContainer{
    width: fit-content;
    height: fit-content;
}
.homeContainer h1{
    font-size: 6rem;
    font-weight: 700;
    color: var(--primaryColor);
}
.homeContainer p{
    width: fit-content;
    margin: 1rem auto 3rem;
    padding: 1rem 3rem;
    font-size: 2rem;
    font-weight: 700;
    color: #fcfdfc;
    background-color: var(--primaryColor);
    border-radius: 54px;
    cursor: pointer;
}

@media screen and (min-width: 768px){
    .homeContainer h1{
        font-size: 10rem;
    }
}